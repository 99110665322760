import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mainWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheNavBar = _resolveComponent("TheNavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isDarkMode ? `relative h-full min-h-screen darkMode` : `relative h-full min-h-screen lightMode`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TheNavBar, {
        onDarkMode: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleDarkMode()))
      }),
      _createVNode(_component_router_view)
    ])
  ], 2))
}