import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "with-title" }
const _hoisted_2 = { class: "flex mb-5" }
const _hoisted_3 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FarmerRewardDisplay = _resolveComponent("FarmerRewardDisplay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FarmerRewardDisplay, {
          key: _ctx.farmerAcc.rewardA,
          farmReward: _ctx.farmAcc.rewardA,
          reward: _ctx.farmerAcc.rewardA,
          status: _ctx.parseFarmerState(_ctx.farmerAcc),
          staked: _ctx.farmerAcc.gemsStaked,
          period: _ctx.parseDate(_ctx.farmerAcc.minStakingEndsTs),
          vault: _ctx.vault,
          title: "Reward A"
        }, null, 8, ["farmReward", "reward", "status", "staked", "period", "vault"])
      ])
    ])
  ]))
}