
import ConfigPane from '@/components/ConfigPane.vue';
import { defineComponent, computed, ref, onMounted, defineEmits } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import NavButton from '@/components/NavButton.vue';

export default defineComponent({
  components: { NavButton, ConfigPane },
  setup(props, {emit}) {
    const route = useRoute();
    const path = computed(() => route.path);

    const menu = [
      { name: 'Dashboard', url: '/' },
      { name: 'Time Pass', url: 'https://google.com' },
      { name: 'Leaderboard', url: '/leaderboard' },
      { name: 'Community', url: '/community' },
      { name: 'Shop', url: 'https://google.com' },
      { name: 'Profile', url: '/profile' },
    ];

    const darkMode = ref<boolean>(true)
    const dropdown = ref<boolean>(false)

    const handleIt = () => {
      darkMode.value = !darkMode.value
      emit('darkMode', "hi")
    }

    const router = useRouter()
    const changeRoute = () => {
      router.push('/')
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }

    return {
      menu,
      path,
      darkMode,
      handleIt,
      dropdown,
      changeRoute
    };
  },
});
