
import { computed, defineComponent, ref, watch, onMounted } from 'vue';
import { WalletName } from '@solana/wallet-adapter-wallets';
import useCluster, { Cluster } from '@/composables/cluster';
import useWallet from '@/composables/wallet';
import { Wallet } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { farmAddress, rpc, server } from '@/config';
import { initGemBank } from '@/common/gem-bank';
import {
  getNFTMetadataForMany,
  getNFTsByOwner,
  INFT,
} from '@/common/web3/NFTget';
import { findFarmerPDA, stringifyPKsAndBNs } from '@gemworks/gem-farm-ts';
import { initGemFarm } from '@/common/gem-farm';
import axios from 'axios';
import Alert from "../components/Alert.vue";


export default defineComponent({
  components: {
    Alert
  },
  setup() {

    const showLogin = ref<boolean>(false)
    const address = ref<string>()

    // cluster
    const { cluster, setCluster, getClusterURL, getConnection } = useCluster();
        setCluster(server === "Devnet" ? Cluster.Devnet : server === "Mainnet" ? Cluster.Mainnet : Cluster.Devnet);
    const chosenCluster = computed({
      get() {
        return cluster.value;
      },
      set(newVal: Cluster) {
        setCluster(server === "Devnet" ? Cluster.Devnet : server === "Mainnet" ? Cluster.Mainnet : Cluster.Devnet);
      },
    });

    // wallet
    const { getWalletName, setWallet, wallet, getWallet } = useWallet();
    const chosenWallet = computed({
      get() {
        return getWalletName();
      },
      set(newVal: WalletName | null) {
        setWallet(newVal, getClusterURL());
      },
    });

    const chooseWallet = (wallet: WalletName) => {
      setWallet(wallet, getClusterURL())
    }

    watch([wallet], async() => {
      if (wallet.value?.publicKey?.toBase58() !== undefined) {
        if (getWallet() && getConnection()) {
          address.value = getWallet()!.publicKey!.toBase58();
        }
      } else {
        address.value = ''
        showLogin.value = false
      }
    })

    const shortenAddress = (wallet: string) => {
      return wallet.toString().substring(0, 4) + "..." + wallet.toString().substring(wallet.length-3, wallet.length)
    }


//========================================================================================================================================//


    return {
      Cluster,
      chosenCluster,
      WalletName,
      chosenWallet,
      chooseWallet,
      showLogin,
      address,
      shortenAddress
    };
  },
});
