import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, renderList as _renderList, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nftBox"
}
const _hoisted_2 = { class: "flex flex-wrap" }
const _hoisted_3 = { class: "nftTitleBox" }
const _hoisted_4 = { class: "outerCard" }
const _hoisted_5 = { class: "flex gapThis" }
const _hoisted_6 = {
  key: 0,
  class: "loadingAnimation"
}
const _hoisted_7 = {
  key: 0,
  class: "noneStaked"
}
const _hoisted_8 = {
  key: 1,
  class: "nftBox"
}
const _hoisted_9 = { class: "flex flex-wrap vaultWrap" }
const _hoisted_10 = { class: "nftTitleBox" }
const _hoisted_11 = {
  class: "flex",
  style: {"gap":"10px"}
}
const _hoisted_12 = { class: "outerCard" }
const _hoisted_13 = { class: "flex gapThis" }
const _hoisted_14 = {
  key: 0,
  class: "loadingAnimation"
}
const _hoisted_15 = {
  key: 0,
  class: "noneStaked"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFTCard = _resolveComponent("NFTCard")!
  const _component_DisplayRefresh = _resolveComponent("DisplayRefresh")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.title === 'Wallet')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.nfts?.length! > 0 && _ctx.selectedWalletNFTs > 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "big-button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nfts?.length! > 0 ? _ctx.$emit('stake', 'stakeNotAll') : null))
                  }, "Stake (" + _toDisplayString(_ctx.selectedWalletNFTs) + ")", 1))
                : _createCommentVNode("", true),
              (_ctx.nfts?.length! > 0 && _ctx.selectedWalletNFTs === 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "big-button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.nfts?.length! > 0 ?  _ctx.$emit('stake', 'stake') : null))
                  }, "Stake All"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.nfts?.length! === 0)
                        ? (_openBlock(), _createElementBlock("h4", _hoisted_7, "No NFT's in your study group!"))
                        : _createCommentVNode("", true)
                    ], 64)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft) => {
                  return (_openBlock(), _createBlock(_component_NFTCard, {
                    key: nft,
                    nft: nft,
                    length: _ctx.calcLength(),
                    state: 'Wallet',
                    isStaked: _ctx.isStaked,
                    refreshXP: _ctx.refreshXP,
                    farmerAcc: _ctx.farmerAcc,
                    onSelected: _ctx.handleSelected
                  }, null, 8, ["nft", "length", "isStaked", "refreshXP", "farmerAcc", "onSelected"]))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.title === 'Vault')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                (_ctx.isStaked === 'staked' && !_ctx.showRefreshButton)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "big-button",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('claim', 'claim')))
                    }, _toDisplayString(+((((Date.now() - _ctx.farmerAcc?.rewardA.fixedRate.lastUpdatedTs*1000)/1000)*(_ctx.farmerAcc?.rewardA.fixedRate.promisedSchedule.baseRate/1000000000))* (+_ctx.farmerAcc?.gemsStaked)).toFixed(4) > 0 ? `Claim` : 'All claimed!'), 1))
                  : _createCommentVNode("", true),
                (_ctx.isStaked === 'staked')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "big-button",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.unstakeAll()))
                    }, "Unstake All"))
                  : _createCommentVNode("", true),
                (_ctx.isStaked === 'pendingCooldown')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 2,
                      class: "big-button",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('unstake', 'endcooldown')))
                    }, "End Cooldown"))
                  : _createCommentVNode("", true),
                (_ctx.isStaked === 'unstaked' && _ctx.nfts?.length! > 0 && _ctx.selectedVaultNFTs === 0)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 3,
                      class: "big-button",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('unstake', 'reclaimAll')))
                    }, "Reclaim All NFT's"))
                  : _createCommentVNode("", true),
                (_ctx.isStaked === 'unstaked' && _ctx.nfts?.length! > 0 && _ctx.selectedVaultNFTs > 0)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 4,
                      class: "big-button",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('unstake', 'reclaim')))
                    }, "Reclaim NFT's (" + _toDisplayString(_ctx.selectedVaultNFTs) + ")", 1))
                  : _createCommentVNode("", true),
                (_ctx.isStaked === 'unstaked' && _ctx.nfts?.length! > 0)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 5,
                      class: "big-button",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('stake', 'stakeAgain')))
                    }, "Restake NFT's"))
                  : _createCommentVNode("", true),
                (_ctx.isStaked === 'staked' && _ctx.showRefreshButton === true)
                  ? (_openBlock(), _createBlock(_component_DisplayRefresh, {
                      key: _ctx.farmerAcc,
                      farm: _ctx.farm,
                      farmAcc: _ctx.farmAcc,
                      farmer: _ctx.farmer,
                      farmerAcc: _ctx.farmerAcc,
                      onRefreshFarmer: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('refresh-farmer')))
                    }, null, 8, ["farm", "farmAcc", "farmer", "farmerAcc"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.nfts?.length! === 0)
                        ? (_openBlock(), _createElementBlock("h4", _hoisted_15, "No NFT's in the library!"))
                        : _createCommentVNode("", true)
                    ], 64)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft) => {
                  return (_openBlock(), _createBlock(_component_NFTCard, {
                    key: nft,
                    nft: nft,
                    length: _ctx.calcLength(),
                    state: 'Vault',
                    isStaked: _ctx.isStaked,
                    refreshXP: _ctx.refreshXP,
                    farmerAcc: _ctx.farmerAcc,
                    onSelected: _ctx.handleSelected
                  }, null, 8, ["nft", "length", "isStaked", "refreshXP", "farmerAcc", "onSelected"]))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}