import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/refresh.png'


const _hoisted_1 = { class: "refreshIMG" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refreshFarmer && _ctx.refreshFarmer(...args)))
    })
  ]))
}