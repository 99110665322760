
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    message: String,
    type: String
  },
  setup(props) {



    return {

    };
  },
});
