import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { class: "flex flex-row navbar" }
const _hoisted_2 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigPane = _resolveComponent("ConfigPane")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        className: "navLogo",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeRoute())),
        src: _imports_0,
        style: {"height":"70%"}
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_ConfigPane)
    ])
  ]))
}