//change this farm address to your address
export const farmAddress = "2o4PQoPEuycWBXEzRBzud21yDYTygewo86xRJjdupvw2"

//might want to consider using your own RPC node
export const rpc = "https://ssc-dao.genesysgo.net/"

//change to "Mainnet" when ready to deploy
export const server = "Devnet"

export const collectionSize = 3333

//do not change this!! if the claim button is consistently having issues displaying the correct amount, then I recommend changing this back to "true", which is the way Gem Farm intended for their system to work
export const showRefreshButton = false