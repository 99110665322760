
import { defineComponent } from 'vue';
import TheNavBar from './components/TheNavBar.vue';
import TheFooter from './components/gem-farm/TheFooter.vue';
import { ref } from 'vue'
export default defineComponent({
  components: { TheFooter, TheNavBar },
  setup() {
    const isDarkMode = ref<boolean>(false)
    const handleDarkMode = function() {
      isDarkMode.value = !isDarkMode.value
      console.log(isDarkMode.value)
    }
    return {
      handleDarkMode,
      isDarkMode
    }
  }
});
